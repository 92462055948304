"use client";

import styled from "styled-components";
import gradient from "@/public/staticfiles/gradient.png";

export const HeaderCloud = styled.div`
  background: url(${gradient.src}) center top no-repeat;
  background-size: contain;
  width: 100%;
  min-width: 600px;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: -1;
`;
