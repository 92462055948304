"use client";

import React from "react";

function useScroll({
  ref,
  thresholdExceedCallback = () => {},
  thresholdReturnCallback = () => {},
}: {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  thresholdExceedCallback?: () => void;
  thresholdReturnCallback?: () => void;
}) {
  const [triggered, setTriggered] = React.useState(false);
  const offset = ref.current?.clientHeight ?? 80;

  React.useEffect(() => {
    const listener = () => {
      if (window.scrollY >= offset && !triggered) {
        setTriggered(true);
        thresholdExceedCallback();
      }
      if (window.scrollY < offset && triggered) {
        setTriggered(false);
        thresholdReturnCallback();
      }
    };

    listener();

    window.addEventListener("scroll", listener);

    return () => window.removeEventListener("scroll", listener);
  }, [triggered, offset, thresholdExceedCallback, thresholdReturnCallback]);

  return null;
}

export default useScroll;
