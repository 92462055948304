import(/* webpackMode: "eager" */ "/app/app/app.css");
import(/* webpackMode: "eager" */ "/app/entities/banners/ui/AppBanner/AppBanner.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper.css");
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/navigation.css");
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/pagination.css");
import(/* webpackMode: "eager" */ "/app/processes/contexts/analytics/context.tsx");
import(/* webpackMode: "eager" */ "/app/processes/contexts/device-info/context.tsx");
import(/* webpackMode: "eager" */ "/app/processes/contexts/one-link/context.tsx");
import(/* webpackMode: "eager" */ "/app/processes/layouts/hub-layout/styled.tsx");
import(/* webpackMode: "eager" */ "/app/processes/styled-registry.tsx");
import(/* webpackMode: "eager" */ "/app/shared/ui/cloud/styled.ts");
import(/* webpackMode: "eager" */ "/app/widgets/footer/ui/Footer.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/navbar/ui/Navbar.tsx")