import styled from "styled-components";

export const Wrapper = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  display: flex;
  grid-gap: 10px;
  align-items: center;
`;
