const navbarPinCallback = (element?: HTMLElement | null) => {
  if (!element) return;

  element.classList.add("pinned");
  document.body.classList.add("navbar-pinned");
  document.body.style.setProperty(
    "--navbar-height",
    `${element.clientHeight}px`
  );
};

export default navbarPinCallback;
