import styled, { css } from "styled-components";

export const Wrapper = styled.div(
  () => css`
    z-index: 2;
    padding: 20px 50px;
    transition: all 0.3s var(--easing-func);
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 15px;

    > div {
      width: 0;
    }

    &:after {
      content: "";
      height: 1px;
      width: calc(100% - 100px);
      margin: 0 auto;
      position: absolute;
      bottom: 0;
      background: var(--border-color);
      z-index: -1;
    }

    > div:last-child {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &.pinned {
      width: 100%;
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      background: #1c1c1c;
      animation: animate 0.3s var(--easing-func);

      &:after {
        display: none;
      }

      @keyframes animate {
        0% {
          top: -100%;
        }
        100% {
          top: 0;
        }
      }
    }

    @media (max-width: 1280px) {
      padding: 20px 16px;
      &:after {
        width: calc(100% - 32px);
      }
    }

    @media (max-width: 768px) {
      > div {
        width: unset;

        &:last-child {
          display: none;
        }
      }
    }
  `,
);
