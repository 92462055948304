"use client";

import styled from "styled-components";
import { Container } from "@/shared/ui/container";

export const StyledContainer = styled(Container)`
  margin: 40px auto 100px auto;

  @media (max-width: 768px) {
    margin: 30px auto 50px auto;
  }
`;
