import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import logo from '@/public/staticfiles/logo/logo_white.svg';
import { Wrapper } from './styled';

function Logo() {
  const router = useRouter();

  return (
    <Wrapper
      onClick={() => {
        router.push('/');
      }}
    >
      <Image height={24} src={logo} alt="logo" />
    </Wrapper>
  );
}

export { Logo };
